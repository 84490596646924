button {
    padding: 0.5rem;
    border-radius: 1rem;
    border: none;
}
.card {
    color: black;
    margin: 10px;
    padding: 9rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 2px 2px 2px #ccc;
}

.card-content {
    display: grid;
    gap: 1rem;
}

.card-buttons {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
}

