.popover-wrapper {
    padding: 1rem;
}

.popover-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close-icon {
        &:hover {
            cursor: pointer;
        }
    }
}

.meetingUrl {
    background-color: #ededed;
    padding: 0.5rem;
    border-radius: var(--border-radius);
}

.copy-btn {
    background-color: var(--iris);
    color: #fff;
    font-size: 1rem;
    padding: 0.5rem 1rem;
}

.copy-btn:hover {
    cursor: pointer;
    opacity: 0.9;
    transition: 0.3s;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.meeting-link-body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.meeting-link-body button {
    width: fit-content;
}
.meeting-link-body p {
    margin: 0;
}