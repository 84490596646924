.join-modal-wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
}

.faded {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.normal {
    backdrop-filter: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.join-modal {
    height: 200px;
    width: 200px;
    border: 2px solid red;
}

.button-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.video-container {
    /* height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 0.5rem; 
     */
     height: 100%;
     width: 100%;
     max-width: 100vw;
     display: grid;
     grid-template-columns: 1fr 1fr;
     height: 100%;
}


video {
    border-radius: 0.25rem;
    object-fit: cover;
    /* width: calc(100% - 10rem); */
    width: 100%;
    height: 540px;
}

.meeting-controls {
    width: 100%;
    .controls--video-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border: none;
        background-color: #f0f0f0;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;
      }
      
      .controls--video-btn:hover {
        background-color: #e0e0e0;
        transform: scale(1.05);
      }

      .enabled {
        border-radius: 2rem;
      }

      .disabled {
        border-radius: 0.5rem;
      } 

      .controls-wrapper {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
      }
}

.left-video {
    background-color: var(--ivory);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 1rem;
    border-radius: var(--border-radius);
    margin: 1rem;
}

.right-video {
    background-color: var(--iris);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 1rem;
    border-radius: var(--border-radius);
    margin: 1rem;
}

.video-wrapper {
    border-radius: 0.5rem;
    border-radius: var(--border-radius);
}

/* Media query to switch to horizontal layout if the screen is wide enough */
@media (min-width: 1200px) {
    .video-container {
        grid-template-rows: none;
        grid-template-columns: 1fr 1fr;
    }

    video {
        /* width: calc(100% - 2rem); */
        height: calc(100vh - 10rem);
    }
}

      /* mobile media query */
      @media (max-width: 768px) {
        .video-container {
            grid-template-rows: 1fr 1fr;
            grid-template-columns: none;
        }
        video {
            height: 366px;
        }
        .left-video, .right-video {
            margin: 0;
        }
      }

.video {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.video-off-raining {
    height: 200px;
}