.app {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    background-color: var(--ivory);
    overflow: hidden;
    h1 {
        font-family: "Parisienne", cursive;
        font-weight: 900;
        font-style: normal;
        z-index: 1;
        position: absolute;
        top: 0;
        color: white;
        font-size: 4rem;
    }
}
.backgroundImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.controls {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 4.5rem;
    /* background-color: white; */
    border-radius: var(--border-radius);
    /* box-shadow: rgba(0, 0, 0, 0.45) 17px 17px 20px; */
    &:hover {
        cursor: pointer;
    }
}
/* .blob {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  width: fit-content;
  outline: 1px #bdbdbd solid;
  box-sizing: border-box;
  border: 3px transparent solid;
}

.blob svg {
  position: absolute;
  width: 100%;  
  height: 100%;
  display: block;
  transform-origin: 50% 50%;
  transition: transform .75s 0s;
  overflow: hidden;
 
}
[id^="blobmask"] rect { 
  fill: #eee; 
  width: 100%; 
  height: 100% }

[id^="blobmask"] path { 
  fill: #000; }
[id^="blobmask"] path { 
  fill: #000; }


[id="blobmask-3"] rect { 
  fill: #fff; 
}
.blob:hover svg {
  transition-duration: 2s;
  transform: scale(12);  
}

.blob path {
 animation: rotatepath 47s linear 0s infinite;
}
.blob-gray {
  position: relative;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  display: block;
}
@keyframes rotatepath {
  0% { transform: translate(50%, 50%) scale(2.4) rotateZ(360deg); }
  100% { transform:  translate(50%, 50%) scale(2.4) rotateZ(0deg); }  
} */

.newMeetingButton {
  background-color: var(--dark);
  color: var(--ivory);
}

.controlButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
  width: max-content;
  padding: 0.75rem;
  border-radius: 0.25rem;
  flex-direction: row-reverse;
}

.controlInputWrapper {
  border: 1px solid var(--dark);
  background-color: white;
}
.controlInput {
  border: none;
  padding: 0.5rem;
  font-size: 1rem;
  &:focus {
    outline: none;
  }
}
input::placeholder {
  font: 16px 'Roboto', sans-serif;
}

.background {
  position: absolute;
  height: auto;
  width: 100%;
}

.wrapper {
  height: 100%;
  width: 100%;
  max-width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .inner {
    /* height: 314px;
    width: 496px; */
    padding: 0;
    width: calc(100% - 2rem);
    height: calc(100vh - 10rem);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    text-align: left;
    max-width: 100vw;
    .left-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      /* align-items: flex-start; */
      align-items: center;
      gap: 1.5rem;
      /* padding: 4rem; */
      /* max-width: calc(100% - 2rem);
      max-height: calc(100vh - 12rem); */
      background-color: var(--ivory);
      /* box-shadow: rgba(0, 0, 0, 0.45) 17px 17px 20px; */
      border-radius: var(--border-radius);
      h2 {
        margin: 0;
      }
      h3 {
        margin: 0;
      }
    }
    .left-svg {
      height: 250px;
      /* margin: 1.5rem 0; */
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    max-width: 100vw;
    .right-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      /* padding: 4rem; */
      /* max-width: calc(100% - 2rem);
      max-height: calc(100vh - 12rem); */
      background-color: var(--iris);
      /* box-shadow: rgba(0, 0, 0, 0.45) 17px 17px 20px; */
      border-radius: var(--border-radius);
    }
  }
}


/* BLOB JSX */
/* <div className="blob">
        <svg viewBox="0 0 500 500" preserveAspectRatio="none">
          <defs>
            <linearGradient id="blobgradient-1">
              <stop offset="0%" stopColor="#e8e8e8"></stop>
              <stop offset="100%" stopColor="#e8e8e8"></stop>
            </linearGradient>
          </defs>

          <mask id="blobmask-1">
            <rect></rect>

            <path className="theBlob">
              <animate
                attributeName="d"
                begin="-2s"
                dur="15s"
                repeatCount="indefinite"
                values="

M42.4,-69.5C54.5,-66.4,63.6,-54.2,59.1,-41.1C54.6,-28,36.6,-14,38.4,1C40.2,16.1,61.9,32.2,67,46.3C72,60.4,60.5,72.6,46.6,69.5C32.6,66.4,16.3,48,3.4,42.1C-9.5,36.1,-19,42.7,-32.2,45.4C-45.4,48.1,-62.4,47,-67.3,38.8C-72.2,30.6,-64.9,15.3,-61,2.3C-57.1,-10.8,-56.5,-21.5,-54.9,-35.4C-53.3,-49.3,-50.7,-66.3,-41.4,-70.9C-32,-75.5,-16,-67.9,-0.4,-67.1C15.1,-66.3,30.3,-72.5,42.4,-69.5Z;
         M33.1,-67.4C35.6,-55.9,25.3,-32.3,24,-19C22.8,-5.8,30.6,-2.9,37.2,3.8C43.8,10.5,49.3,21.1,47.1,28.4C44.9,35.7,35.2,39.9,26.1,47.4C16.9,54.9,8.5,65.8,1.5,63.2C-5.5,60.6,-11,44.6,-22.2,38.3C-33.4,31.9,-50.3,35.4,-51,30.6C-51.7,25.8,-36.2,12.9,-27.7,4.9C-19.1,-3.1,-17.5,-6.1,-16.8,-10.8C-16,-15.5,-16.1,-21.8,-13.5,-33.2C-10.8,-44.6,-5.4,-61.2,5,-69.8C15.3,-78.3,30.6,-78.9,33.1,-67.4Z;
         M30.3,-57C40.4,-46.6,50.4,-40.8,51.7,-32C52.9,-23.3,45.4,-11.6,39.4,-3.4C33.5,4.8,29.1,9.5,30.8,23.3C32.4,37,40,59.7,35.8,63.9C31.7,68.1,15.8,53.8,4.7,45.6C-6.4,37.4,-12.7,35.2,-22.6,34.3C-32.5,33.5,-46,33.9,-57.7,28.4C-69.3,22.9,-79,11.5,-78.3,0.5C-77.5,-10.5,-66.1,-21.1,-54.8,-27C-43.4,-33,-32,-34.3,-22.9,-45.3C-13.7,-56.2,-6.9,-76.6,1.6,-79.4C10.1,-82.2,20.2,-67.4,30.3,-57Z;
         M27.3,-56.9C30,-45.7,23.2,-27.6,28.1,-16.9C32.9,-6.3,49.5,-3.2,52.9,2C56.3,7.1,46.6,14.2,40,21.8C33.4,29.3,29.8,37.3,23.6,40.1C17.5,43,8.7,40.5,-1.6,43.2C-11.8,45.9,-23.7,53.7,-30.2,51.1C-36.6,48.5,-37.8,35.5,-46.3,25.2C-54.9,15,-71,7.5,-78.2,-4.2C-85.5,-15.9,-84.1,-31.8,-75.7,-42.5C-67.4,-53.2,-52.3,-58.8,-38.5,-63.7C-24.8,-68.5,-12.4,-72.7,-0.1,-72.6C12.3,-72.5,24.5,-68.1,27.3,-56.9Z;
         M35,-59.3C44.5,-55,50.9,-44.1,56.4,-33.1C61.9,-22,66.6,-11,61.4,-3C56.3,5.1,41.3,10.1,33.5,17.2C25.8,24.3,25.2,33.5,20.8,45.8C16.4,58.2,8.2,73.7,2.8,68.8C-2.5,63.9,-5.1,38.5,-15.7,29.8C-26.3,21,-44.9,28.9,-52.1,26.7C-59.3,24.5,-55,12.2,-55.6,-0.3C-56.2,-12.9,-61.7,-25.9,-56.8,-32C-51.9,-38.2,-36.6,-37.6,-25.4,-40.9C-14.2,-44.2,-7.1,-51.4,2.8,-56.2C12.7,-61,25.4,-63.5,35,-59.3Z;
         M35.2,-56.3C48,-53.6,62.4,-49,65.9,-39.3C69.4,-29.6,61.9,-14.8,51.2,-6.2C40.5,2.4,26.5,4.8,18.8,7.2C11.1,9.6,9.7,11.9,7.6,17C5.5,22,2.7,29.9,-2.8,34.8C-8.4,39.7,-16.8,41.7,-19.7,37.1C-22.7,32.5,-20.1,21.3,-30.2,14C-40.3,6.7,-63.1,3.4,-73.7,-6.1C-84.3,-15.6,-82.7,-31.2,-69.9,-33.9C-57.2,-36.7,-33.4,-26.6,-19.8,-28.9C-6.3,-31.2,-3.2,-45.9,4,-52.9C11.2,-59.8,22.4,-59,35.2,-56.3Z;
         M42.4,-69.5C54.5,-66.4,63.6,-54.2,59.1,-41.1C54.6,-28,36.6,-14,38.4,1C40.2,16.1,61.9,32.2,67,46.3C72,60.4,60.5,72.6,46.6,69.5C32.6,66.4,16.3,48,3.4,42.1C-9.5,36.1,-19,42.7,-32.2,45.4C-45.4,48.1,-62.4,47,-67.3,38.8C-72.2,30.6,-64.9,15.3,-61,2.3C-57.1,-10.8,-56.5,-21.5,-54.9,-35.4C-53.3,-49.3,-50.7,-66.3,-41.4,-70.9C-32,-75.5,-16,-67.9,-0.4,-67.1C15.1,-66.3,30.3,-72.5,42.4,-69.5Z;
       "
              ></animate>
            </path>
          </mask>

          <rect
            width="500"
            height="500"
            mask="url('#blobmask-1')"
            fill="url('#blobgradient-1')"
          ></rect>
        </svg>
        <div className="blob-gray"></div>
      </div> */


      /* mobile media query */
@media (max-width: 768px) {
  .wrapper {
    grid-template-columns: 1fr;
    .inner {
      height: 100%;
      /* width: auto; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .left {
      .left-inner {
        padding: 2rem;
        height: 100%;
        /* width: 100%;
        max-width: unset; */
      }
    }
    .right {
      .right-inner {
        padding: 2rem;
      }
    }
  }

} 